$region-wrapper-width: 15rem;

.contact-person {
    width: 100%;
    padding: 1.5rem;
    background-color: $primary;
    color: $white;

    &--default {
        border-radius: $border-radius-default;

        body:not(.browser--internet-explorer) & {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        @include media-breakpoint-up(md) {
            //max-width: 21rem;
            //margin-left: calc(100% - 21rem);
        }

        &.has-region {
            .contact-person {
                @include media-breakpoint-up(lg) {
                    &__row {
                        display: flex;
                        margin-left: -1rem;
                        margin-right: -1rem;
                    }

                    &__wrapper {
                        width: 100%;
                        max-width: calc(100% - #{$region-wrapper-width});
                        flex-basis: calc(100% - #{$region-wrapper-width});
                    }

                    &__region-wrapper {
                        width: 100%;
                        max-width: $region-wrapper-width;
                        flex-basis: $region-wrapper-width;
                    }

                    &__map-wrapper {
                        height: 20rem;
                    }

                    &__region-text {
                        width: 10rem;
                    }
                }

                @include media-breakpoint-down(md) {
                    &__region-wrapper {
                        margin-top: 2rem;
                        margin: 0 -1rem;
                    }

                    &__region-text, &__map-wrapper {
                        max-width: 50%;
                        flex-basis: 50%;
                        padding: 0 1rem;
                    }
                }

                &__wrapper, &__region-wrapper {
                    padding: 0 1rem;
                }

                &__region-wrapper {
                    display: flex;

                    @include media-breakpoint-down(sm) {
                        flex-direction: column;

                        .contact-person__region-text {
                            margin-top: 2rem;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        margin-top: 2rem;
                    }

                    @include media-breakpoint-up(lg) {
                        flex-direction: column;
                        align-items: flex-end;
                    }
                }
            }
        }

        .contact-person {
            &__heading {
                display: none;
            }

            &__image--wrapper {
                margin-bottom: 2rem;
                display: inline-block;
                border-radius: 50%;
                overflow: hidden;
            }

            &__image {
                margin-bottom: 0;
            }

            &__contact {
                text-align: center;

                .button {
                    width: auto;
                    min-width: 80%;
                    text-align: center;
                }

                .contact-person__button {
                    width: 100%;
                    margin-top: 2rem;
                }

                &:only-child .contact-person__button {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            &__region-text {
                color: $white;

                p, a, strong, span {
                    color: inherit;
                }
            }
        }
    }

    &--sidebar {
        border-radius: $border-radius-default;

        .contact-person {
            &__image {
                padding-bottom: 100%;
            }
        }
    }

    &--row {
        border-radius: $border-radius-default;

        > .container {
            @media screen and (min-width: 768px) {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }

        .contact-person {

            &__contact__button--wrapper {
                flex: 0 0 100%;

                .contact-person__button {
                    @media screen and (max-width: 767px) {
                        width: 100%;
                        margin-top: 2rem;
                        text-align: center;
                    }
                }
            }

            &__wrapper {
                @media screen and (min-width: 576px) {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                }
            }

            &__image--wrapper {
                display: inline-block;
                border-radius: 50%;
                overflow: hidden;
                margin: 0;
                @media screen and (min-width: 576px) {
                    width: 252px;
                }
            }

            &__data {
                margin: 0 1rem;
                @media screen and (max-width: 767px) {
                    width: 50%;
                }
            }

            &__image {
                margin-bottom: 0;
            }

            &__map {
                max-height: 252px;
            }

            &__contact {
                text-align: center;

                .button {
                    width: auto;
                    min-width: 80%;
                    text-align: center;
                }

                .contact-person__button {
                    width: 100%;
                    margin-top: 2rem;
                }

                &:only-child .contact-person__button {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            &__region-text {
                color: $white;

                p, a, strong, span {
                    color: inherit;
                }
            }
        }
    }

    &__heading {
        display: none;
    }

    &__image {
        width: 100%;
        height: 0;
        margin-bottom: 2rem;
        display: inline-block;
        background-position: center center;
        background-size: cover;
        border-radius: 50%;

        img {
            display: none;
        }
    }

    &__name, &__position, &__phone {
        display: block;
        color: inherit;
    }

    &__name {
        font-size: 1.75rem;
        font-weight: $font-weight-bold;
    }

    &__position {
        font-weight: $font-weight-light;
    }

    &__phone {
        font-size: 1.75rem;
        font-weight: $font-weight-light;
        margin-top: 1rem;
    }

    &__email {
        font-size: 1.25rem;
        font-weight: $font-weight-light;
        margin-top: 1rem;
        color: $white;
    }

    &__socials {
        margin: 2rem -.75rem 0;
        display: flex;
        align-items: center;
    }

    &__social-icon {
        padding: 0 .75rem;
        font-size: 1.5rem;
        color: $white;
    }

    &__contact {
        .contact-person__button {
            width: 100%;
            margin-top: 2rem;
        }

        &:only-child .contact-person__button {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__qr-code {
        display: none;
    }

    &-flyout {
        color: $white;
        display: block;
        position: fixed;
        top: 0;

        @media only screen and (min-width: 768px) {
            top: 7rem;
            transition: .5s all ease;
        }

        &.closed {
            left: 100%;
            transform: translateX(0);

            .contact-person-flyout--container {
                display: none;
            }

            .contact-person-flyout--button {
                background-color: $secondary;
            }

            .contact-person-flyout--overlay {
                display: none;
            }
        }

        &--wrapper {
            position: relative;
        }

        &.open {
            left: 100%;
            @media only screen and (min-width: 768px) {
                transform: translateX(-100%);
            }

            .contact-person-flyout {
                &--overlay {
                    display: block;
                    width: 100%;
                    height: 100vh;
                    background-color: rgba(0, 0, 0, .7);
                    position: fixed;
                    bottom: 0;
                    right: 0;
                }

                &--wrapper {
                    @media only screen and (max-width: 767px) {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100vh;
                        overflow: scroll;
                    }
                }

                &--close {
                    position: absolute;
                    top: 10px;
                    right: 10px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        &--content {
            background-color: $primary;
            position: relative;
            right: 0;
            top: 0;
            z-index: 9999999999;
            text-align: center;
            padding-top: 2rem;
            padding-bottom: 2rem;
            border-bottom-left-radius: 25px;
            color: $white;

            @media only screen and (max-width: 767px) {
                border-bottom-left-radius: 0px;
                right: 0;
                top: 0;
                width: 100%;
                height: auto;
                position: absolute;
            }

            .contact-person__socials {
                justify-content: center;
            }
        }

        &--container {
            padding-left: 2rem;
            padding-right: 2rem;

            @media only screen and (min-width: 768px) {
                flex-shrink: 0;
                flex-grow: 1;
                min-width: 350px;
                width: 50%;
            }

            &.contact-person--second {

                .contact-person-flyout__image--wrapper {
                    @media only screen and (max-width: 767px) {
                        border-top: 1px solid $white;
                        margin-top: 1.5rem;
                    }
                }

                @media only screen and (min-width: 768px) {
                    border-left: 1px solid $white;
                }
            }
        }

        &--button {
            background-color: #41B6E6;
            color: #fff;
            transform: rotate(-180deg);
            /* transform-origin: 100% 100%; */
            writing-mode: vertical-lr;
            text-orientation: mixed;
            border-bottom-right-radius: 25px;
            border-top-right-radius: 25px;
            padding: 25px 10px;
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            left: -48px;

            @media only screen and (max-width: 767px) {
                top: 7rem;
            }

            &:hover {
                cursor: pointer;
            }

            img {
                transform: rotate(180deg);
                margin-top: 10px;
            }
        }

        @media only screen and (min-width: 768px) {
            &--cp-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 2rem;
            }
        }

        h3 {
            font-size: 1.7rem;
            font-weight: bold;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        &__name {
            font-size: 1.4rem;
            font-weight: bold;
        }

        .small_font {
            font-size: 12px;
            color: $white;
        }

        &__image {
            border-radius: 100% !important;
            margin-top: 1rem;
            margin-bottom: 1.5rem;
            width: 200px;
            height: 200px !important;
            object-fit: cover;
        }

        .contact-person {
            &__phone {
                font-size: 1.4rem;
            }

            &__socials {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }

            &__social-icon {
                font-size: 1.2rem;
            }

            &__contact a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        a {
            color: $white;
        }

        span {
            color: $white;
            display: block;
        }

        .button.button--secondary.button--filled {
            white-space: nowrap;
            font-size: 1rem;
        }
    }
}

.sidebar {
    &__item {
        &--contact-person {
            &.sticky-top {
                top: 15px;
            }
        }
    }
}

.no-height {
    z-index: 999999999;

    .elementor-widget-wrap,
    .elementor-column-gap-default > .elementor-row > .elementor-column > .elementor-element-populated > .elementor-widget-wrap {
        padding: 0;
    }

    .elementor-column {
        min-height: 0;
    }
}

@media screen and (min-width: 768px) {
    .contact-person-flyout--overlay {
        &.show {
            display: block;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, .7);
            position: fixed;
            bottom: 0;
            right: 0;
        }
    }
}
